import React from "react";
import { shape } from "prop-types";
import get from "lodash/fp/get";
import isUndefined from "lodash/fp/isUndefined";
import { publicUserPropType, trackingDataProp } from "../../../../../lib/graphql/propTypes";
import { Flex } from "../../../../ui";
import FullResults from "./FullResults";

class DiscResults extends React.PureComponent {
  static propTypes = {
    data: shape({
      user: publicUserPropType,
    }).isRequired,
    trackingData: trackingDataProp,
  };

  static defaultProps = {
    trackingData: undefined,
  }

  render() {
    const { data, trackingData } = this.props;
    const user = get("user", data);
    const discScores = get("user.scores.disc", data);

    if (isUndefined(discScores)) return null;

    return (
      <Flex m={2} align="inherit" wrap column>
        <FullResults user={user} trackingData={trackingData} />
      </Flex>
    );
  }
}

export default DiscResults;
