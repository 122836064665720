import React from "react";
import { Flex, Box } from "../../../ui";
import { useContentManager } from "../../ContentManager";
import TitleToggleContainer from "../../TitleToggle";
import StrengthsFinderTraits from "./StrengthsFinderTraits";

function StrengthsFinderResults({ data, trackingData }) {
  const { wrapContent } = useContentManager();
  const { title, traits } = data;

  return (
    <Flex m={2} align="flex-start" wrap column>
      <Box w={1}>
        <TitleToggleContainer
          title={title.name}
          description={wrapContent(title)}
          trackingData={trackingData}
        />
      </Box>
      <StrengthsFinderTraits traits={traits} trackingData={trackingData} />
    </Flex>
  );
}

export default StrengthsFinderResults;
