import dropRight from "lodash/fp/dropRight";
import map from "lodash/fp/map";
import toLower from "lodash/fp/toLower";
import toUpper from "lodash/fp/toUpper";
import {
  makePossessive,
  pluralize,
  isEmpty
} from "..";
import { FREE_TEAM_MEMBER_LIMIT } from "../../../lib/constants";
import { NOT_APPLICABLE } from ".";

export const viewingUserDashboard = firstName => `You are viewing ${makePossessive(firstName)} dashboard. Learn more about your team member's personality, strengths and cultural preferences.`;

export const inviteLabel = input => `Invite "${input}"`;

export const noScoresForThisUser = name => `${name} has not taken this assessment.`;

export const assessmentSignUpIntro = assessmentName => `Once you complete the sign-up process, you’ll be able to see a lot more about your personality, skills, strengths, and culture. Including, the results of the ${assessmentName} assessment you just took.`;

export const filterToggleTooltip = (filterName, enabled) => (enabled ? `Clear ${filterName} filter` : `Filter ${pluralize(filterName, 2)}`);

export const perfectForGrowingTeams = ["Perfect for", "growing teams"];

export const userLabel = ({ fullName, email }) => `${fullName} (${email})`;

export const calendarLastUpdatedAt = elapsedTime => (
  elapsedTime === NOT_APPLICABLE
    ? `Last updated ${elapsedTime}`
    : `Last updated ${elapsedTime} ago`
);

export const usersInsightsArePrivate = name => `${makePossessive(name)} insights are set to private`;

export const unauthorizedProfileMessage = (name = "this person") =>
  `You must be in a shared team to see ${makePossessive(name)} full profile`;
export const learnMoreAboutTriad = triad => `Your type is in the ${triad.name}/${triad.secondaryName} Triad. Learn more on your personal dashboard`;

export const learnMoreAboutWing = wing => `You have a wing type ${wing.secondaryName}. Learn more on your personal dashboard.`;

export const userHasNoScores = assessmentName => `This user has no ${assessmentName} scores.`;

export const takeAssessment = assessmentName => `Take ${assessmentName} Assessment`;

export const clientNavigatorTiePrompt = prospectName => `From what you can tell, ${prospectName} `;

export const teamPaywallModalSubtitle = () => `The ${toUpper("free")} Cloverleaf plan includes 1 team of ${FREE_TEAM_MEMBER_LIMIT} members. Upgrade for full access to build better teams!`;

export const removeUserFromOrgPrompt = isGroup =>
  `Removing users from ${isGroup ? "groups" : "organizations"} will also remove them from teams that belong to these ${isGroup ? "groups" : "organizations"}.`;

export const removeUserFromAdminPrompt = isGroup =>
  `This person will lose ${isGroup ? "Group" : "Organization"} Admin privileges and will no longer be able to manage other members of the ${isGroup ? "Group" : "Organization"}.`;

export const removeUserFromOrgButtonLabel = isGroup => `Remove from this ${isGroup ? "group" : "organization"}`;

export const removeUserFromOrgAndSubGroupButtonLabel = isGroup => `Remove from this ${isGroup ? "group" : "organization"} and all sub-groups`;

export const removedUserFromOrgOrGroupToast = isGroup => `User Removed from ${isGroup ? "Group" : "Organization"}`;

export const remainingValue = value => `Remaining: ${value}`;

export const enteredValue = value => `Entered: ${value}`;

export const addEmail = email => `Add "${toLower(email)}"...`;

export const createRole = role => `Create "${role}" Role`;

export const successUpdateRole = ({ isCandidate = false, isRemoving }) => `Successfully ${isRemoving ? "removed" : "updated"} ${isCandidate ? "candidate" : "user"} role`;

export const upgradeStrengthscopeResults = (isUserTheCurrentUser, requesteeName) => `Upgrade ${isUserTheCurrentUser ? "your" : makePossessive(requesteeName)} Strengthscope® results.`;

export const priceWillBeAddedToMonthly = price => `${price} will be added to your monthly invoice`;

export const WHITELIST_NOT_ENABLED_DISCLAIMER = subdomain => `Whitelist restrictions are disabled. Anyone can join this organization using any email domain by visiting https://${subdomain}.cloverleaf.me/signup. No invitation required.`;

export const WHITELIST_ENABLED_DISCLAIMER = subdomain => `Whitelist restrictions are enabled. Only emails under the following domains can join this organization without a direct invitation by visiting "https://${subdomain}.cloverleaf.me/signup".`;

// Entity - team or organization
export const successfullyRevokedInvite = entity => `Successfully revoked invite to the ${entity}`;

export const teamPaywallBannerSubtitle = (hiddenMembers = [], numberOfMembersToShow = 3) => {
  const teamLimitText = `Includes 1 team of ${FREE_TEAM_MEMBER_LIMIT} members.`;
  if (isEmpty(hiddenMembers)) {
    return teamLimitText;
  }
  const teamMembersLength = hiddenMembers.length;
  let namesToShow = map("fullName", hiddenMembers);
  if (teamMembersLength > numberOfMembersToShow) {
    const extraMembers = teamMembersLength - numberOfMembersToShow;
    namesToShow = dropRight(extraMembers, namesToShow);
  }
  const namesExceptLast = dropRight(1, namesToShow);
  if (teamMembersLength <= numberOfMembersToShow) {
    if (namesToShow.length > 1) {
      return (
        `Includes 1 team of ${FREE_TEAM_MEMBER_LIMIT} members. Upgrade now to see ${namesExceptLast.join(", ")} and ${namesToShow[teamMembersLength - 1]} on your team!`
      );
    }

    return (
      `Includes 1 team of ${FREE_TEAM_MEMBER_LIMIT} members. Upgrade now to see ${namesToShow[teamMembersLength - 1]} on your team!`
    );
  }

  return (
    `Includes 1 team of ${FREE_TEAM_MEMBER_LIMIT} members. Upgrade now to see ${isEmpty(namesToShow) ? "the" : `${namesToShow.join(", ")} and`} ${teamMembersLength - numberOfMembersToShow} ${pluralize("other", teamMembersLength - numberOfMembersToShow)} on your team!`
  );
};
