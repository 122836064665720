import React from "react";
import PropTypes from "prop-types";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import map from "lodash/fp/map";
import toUpper from "lodash/fp/toUpper";
import { Flex } from "../../../ui";
import DetailTabs from "../../../ui/DetailTabs";
import { isNullOrUndefined } from "../../../utils";
import { STRENGTHSCOPE_LOCKED_CONTENT } from "../../../utils/strings";
import { ASSESSMENT, GREEN } from "../../../utils/theme";
import AssessmentNugget from "../../TeamDashboard/AssessmentNugget";
import { formatDescriptionsToTabs, formatPremiumDescriptionsToTabs } from "../../TeamDashboard/utils";
import StrengthscopeGraphic from "./StrengthscopeGraphic";

const formatTraits = map(({
  iconUrl,
  name,
  secondaryNameKey,
  showAvatars,
  tabs,
  users,
  value,
}) => ({
  key: name,
  title: name,
  subtitle: !isNullOrUndefined(value) ? `${value}%` : undefined,
  details: !showAvatars ? tabs : { showAvatars, tabs, users },
  color: ASSESSMENT.STRENGTHSCOPE[toUpper(secondaryNameKey)],
  icon: iconUrl,
}));

const addTabsFromTraitDescriptions = (traits = [], trackingData) => traits.map((trait = {}) => ({
  ...trait,
  tabs: (
    <DetailTabs
      details={formatPremiumDescriptionsToTabs(STRENGTHSCOPE_LOCKED_CONTENT, trait)}
      color={ASSESSMENT.STRENGTHSCOPE[toUpper(trait.secondaryNameKey)]}
      trackingData={trackingData}
    />
  ),
}));

const buildTraitsWithAvatars = map(trait => ({
  ...trait,
  showAvatars: true,
}));

function StrengthscopeResults({
  data,
  titleExpanded = false,
  showAvatars = false,
  trackingData,
}) {
  if (!data) return null;

  const title = get("title", data);
  let description = get("title.description", data);
  let asHTML = true;
  let traits = addTabsFromTraitDescriptions(data?.traits, trackingData) || [];
  const graphic = get("graphic", data);
  const isPremium = getOr(false, "isPremium", data);

  if (showAvatars) {
    traits = buildTraitsWithAvatars(traits);
  }

  if (title?.descriptions) {
    asHTML = false;
    description = <DetailTabs details={formatDescriptionsToTabs(title)} color={GREEN} trackingData={trackingData} />;
  }

  return (
    <Flex column mt="10px">
      <AssessmentNugget
        title={title?.name}
        description={description}
        asHTML={asHTML}
        traits={formatTraits(traits, trackingData)}
        column
        startExpanded={titleExpanded}
        trackingData={trackingData}
      />
      {isPremium && graphic && <StrengthscopeGraphic graphicUrl={graphic} />}
    </Flex>
  );
}

StrengthscopeResults.displayName = "StrengthscopeResults";

StrengthscopeResults.propTypes = {
  showAvatars: PropTypes.bool,
  titleExpanded: PropTypes.bool,
  data: PropTypes.shape({
    isPremium: PropTypes.bool,
    title: PropTypes.shape({
      name: PropTypes.string,
      secondaryName: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
    traits: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      secondaryName: PropTypes.string,
      iconUrl: PropTypes.string,
      descriptions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        message: PropTypes.string,
        isPremium: PropTypes.bool,
      })),
    })),
  }),
  trackingData: PropTypes.shape({
    component: PropTypes.string,
    teamId: PropTypes.string,
  }),
};

export default StrengthscopeResults;
