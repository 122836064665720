import compose from "lodash/fp/compose";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import isEqual from "lodash/fp/isEqual";
import keys from "lodash/fp/keys";
import reduce from "lodash/fp/reduce";
import reject from "lodash/fp/reject";
import { ASSESSMENT } from "../../utils/constants";
import {
  STRENGTHS_FINDER_LABEL,
  VIA_LABEL,
  SIXTEEN_TYPES_LABEL,
  INSTINCTIVEDRIVES_LABEL,
  CULTURE_PULSE_LABEL,
  MOTIVATING_VALUES_LABEL,
  DISC_LABEL,
  DRIVING_FORCES_LABEL,
  ENNEAGRAM_LABEL,
  ENERGYRHYTHM_LABEL,
  STRENGTHSCOPE_LABEL
} from "../../utils/strings";
import { itemHasDisc } from "../TeamDashboard/utils";

const {
  STRENGTHS,
  VIA,
  PERSONALITY,
  CULTURE,
  MOTIVATINGVALUES,
  DISC,
  DRIVINGFORCES,
  ENNEAGRAM,
  INSTINCTIVEDRIVES,
  ENERGYRHYTHM,
  STRENGTHSCOPE,
} = ASSESSMENT;

const labels = {
  strengths: STRENGTHS_FINDER_LABEL,
  via: VIA_LABEL,
  personality: SIXTEEN_TYPES_LABEL,
  culture: CULTURE_PULSE_LABEL,
  motivatingvalues: MOTIVATING_VALUES_LABEL,
  disc: DISC_LABEL,
  drivingforces: DRIVING_FORCES_LABEL,
  enneagram: ENNEAGRAM_LABEL,
  instinctiveDrives: INSTINCTIVEDRIVES_LABEL,
  energyRhythm: ENERGYRHYTHM_LABEL,
  strengthscope: STRENGTHSCOPE_LABEL,
};

const assessmentNameToSelectFormat = {
  [STRENGTHS]: "strengths",
  [VIA]: "via",
  [PERSONALITY]: "personality",
  [CULTURE]: "culture",
  [MOTIVATINGVALUES]: "motivatingvalues",
  [DISC]: "disc",
  [DRIVINGFORCES]: "drivingforces",
  [ENNEAGRAM]: "enneagram",
  [INSTINCTIVEDRIVES]: "instinctiveDrives",
  [ENERGYRHYTHM]: "energyRhythm",
  [STRENGTHSCOPE]: "strengthscope",
};

export const getUser = get("user");

export const getProfile = getOr({}, "profile");

export const getCharacteristics = getOr("", "scores.personality.title.characteristics");

export const getScoreOption = assessmentCorrectName => ({
  value: assessmentNameToSelectFormat[assessmentCorrectName],
  label: labels[assessmentNameToSelectFormat[assessmentCorrectName]],
});

const reduceAssessmentSelectOptions = scores => reduce((acc, assessment) => {
  if (assessment === "disc" && !itemHasDisc({ scores })) {
    return acc;
  }

  if (assessment === "motivatingvalues" && scores.drivingforces) {
    return acc;
  }

  if (assessment === "instinctiveDrives" && get("isSearching", scores.instinctiveDrives)) {
    return acc;
  }

  // If the assessment has a truthy value - show it in the user's assessment select
  if (scores[assessment]) {
    return [...acc, { value: assessment, label: labels[assessment] }];
  }

  return acc;
}, []);

export const getSelectOptions = ({ scores }) => compose(
  reduceAssessmentSelectOptions(scores),
  reject(isEqual("__typename")),
  keys,
)(scores);
