import React from "react";
import {
  func,
  arrayOf,
  oneOfType,
  string
} from "prop-types";
import { connect } from "react-redux";
import { track } from "../../lib";
import { updateSelect, getSelectValue } from "../../modules/ui";
import Select from "../ui/Select";
import { isEmpty } from "../utils";
import { baseballCardComponentTypes, EVENT } from "../utils/constants";
import { optionProp } from "../utils/propTypes";

class SelectContainer extends React.PureComponent {
  static propTypes = {
    options: arrayOf(optionProp).isRequired,
    onChange: func.isRequired,
    value: oneOfType([
      optionProp,
      arrayOf(optionProp),
    ]),
    initialOption: optionProp,
    placeholder: string,
  }

  static defaultProps = {
    value: undefined,
    initialOption: undefined,
    placeholder: undefined,
  }

  constructor(props) {
    super(props);
    const {
      options, onChange, value, initialOption, placeholder,
    } = props;

    const dontTrackMe = true;

    // Only initialize the value in Redux if it isn't already there
    if (isEmpty(value) && !placeholder) {
      onChange(initialOption || options[0], dontTrackMe);
    }
  }

  componentDidUpdate() {
    const {
      options, onChange, value, initialOption, placeholder,
    } = this.props;

    // Only initialize the value in Redux if it isn't already there
    if (isEmpty(value) && !placeholder) {
      onChange(initialOption || options[0]);
    }
  }

  render() {
    return <Select {...this.props} value={this.props.value || ""} />;
  }
}

const mapState = (state, props) => ({
  value: getSelectValue(state, props.name),
});

const mapDispatch = (dispatch, props) => ({
  onChange: (value, dontTrackMe = false) => {
    const { name: eventName, props: properties } = EVENT.ContentViewed;
    // Check because function will be called to initialize state regardless of user viewing content
    /** dontTrackMe is an unsafe overloaded second parameter, must check for absolute equality */
    if (dontTrackMe !== true && props.trackingData) {
      track(eventName, {
        contentType: properties.contentType.select,
        ...props.trackingData,
        component: baseballCardComponentTypes[value?.value] || props.trackingData.component,
        action: properties.action.open,
        subElement: value?.value,
      });
    }

    return dispatch(updateSelect(props.name, value));
  },
});

const ConnectedSelectContainer = connect(mapState, mapDispatch)(SelectContainer);

function OverloadedSelectContainer(props) {
  // eslint-disable-next-line react/prop-types
  if (typeof props?.onChange === "function") {
    return <SelectContainer {...props} />;
  }

  return <ConnectedSelectContainer {...props} />;
}

export default OverloadedSelectContainer;
