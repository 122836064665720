import React from "react";
import {
  bool,
  shape,
  string,
  arrayOf
} from "prop-types";
import {
  Box,
  Flex,
  Text
} from "../../../ui";
import { GREEN } from "../../../utils/theme";
import TraitTipsSection from "../../TraitTipsSection";

class UserTraitSection extends React.PureComponent {
  static propTypes = {
    user: shape({
      id: string.isRequired,
      fullName: string.isRequired,
      traitTips: arrayOf(string).isRequired,
    }),
    secondary: bool,
    emptyMessage: string,
  }

  static defaultProps = {
    user: undefined,
    secondary: false,
    emptyMessage: undefined,
  }

  render() {
    const { user, secondary, emptyMessage } = this.props;
    if (!user) {
      return null;
    }

    const { traitTips } = user;

    return (
      <Box w="50%" mt={2}>
        <Flex
          align="center"
          maxWidth={170}
          style={secondary ? { paddingLeft: "8px" } : undefined}
          wrap
        >
          <Text noMargin uppercase condensed style={{ color: GREEN, width: "100%", overflowWrap: "break-word", fontWeight: "bold" }}>
            {user.fullName}
          </Text>
        </Flex>
        <TraitTipsSection isSecondary={secondary} traitTips={traitTips} emptyMessage={emptyMessage} />
      </Box>
    );
  }
}

export default UserTraitSection;
