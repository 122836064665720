import React from "react";
import every from "lodash/fp/every";
import { Flex, Box } from "../../../ui";
import TextBlock from "../../../ui/styled/TextBlock";
import { useContentManager } from "../../ContentManager";
import TitleToggleContainer from "../../TitleToggle";
import PersonalitySpectrum from "./PersonalitySpectrum";
import PersonalityTraits from "./PersonalityTraits";

const isNumericScores = every((trait = {}) => trait.value !== 0);

function PersonalityResults({ data, startExpanded = false, trackingData }) {
  const { wrapContent } = useContentManager();

  const renderSpectrums = () => {
    const { traits = [] } = data || {};

    return (
      traits.map((trait, index) => (
        (trait.value ?
          <PersonalitySpectrum key={trait.name} trait={trait} index={index} />
          : null)
      ))
    );
  }

  const getTitleDetails = (title, traits) => {
    if (!isNumericScores(traits)) {
      return wrapContent(title);
    }

    return (
      <React.Fragment>
        {renderSpectrums()}
        <TextBlock>{wrapContent(title)}</TextBlock>
      </React.Fragment>
    );
  }

  const { title = {}, traits = [] } = data || {};

  return (
    <Flex w={1} p={2} align="flex-start" wrap column>
      <Box w={1}>
        <TitleToggleContainer
          title={title.secondaryName}
          description={getTitleDetails(title, traits)}
          startExpanded={startExpanded}
          trackingData={trackingData}
        />
      </Box>
      <PersonalityTraits traits={traits} trackingData={trackingData} />
    </Flex>
  );
}

export default PersonalityResults;
