import React from "react";
import {
  bool,
  string,
  shape
} from "prop-types";
import get from "lodash/fp/get";
import { track } from "../../../lib/analytics";
import { publicUserPropType, trackingDataProp } from "../../../lib/graphql/propTypes";
import { Flex } from "../../ui";
import {
  ASSESSMENT,
  baseballCardComponentTypes,
  EVENT
} from "../../utils/constants";
import { above, SIDEBAR } from "../../utils/zIndex";
import GreenSelect from "../GreenSelect";
import ScoreView from "./ScoreView";
import { getSelectOptions, getScoreOption } from "./userStatsCard.utils";

export default class ScoreAndSelect extends React.PureComponent {
  static propTypes = {
    selectedScoreType: string,
    initialScoreType: string,
    selectName: string.isRequired,
    selectedTeam: shape({ id: string }),
    user: publicUserPropType.isRequired,
    source: string,
    visible: bool,
    trackingData: trackingDataProp,
  }

  static defaultProps = {
    selectedScoreType: undefined,
    initialScoreType: ASSESSMENT.STRENGTHS,
    selectedTeam: undefined,
    source: undefined,
    visible: false,
    trackingData: undefined,
  }

  componentDidMount() {
    const { selectedTeam, user: { id: userId }, source } = this.props;
    track(EVENT.UserStatsCardViewed.name, {
      targetUserId: userId,
      view: EVENT.UserStatsCardViewed.props.view.scores,
      teamId: get("id", selectedTeam),
      source,
    });
  }

  render() {
    const {
      user, selectedScoreType, initialScoreType, selectName, visible, trackingData,
    } = this.props;

    const display = visible ? "block" : "none";
    const initialScoreOption = getScoreOption(initialScoreType);

    return (
      <div style={{ display }}>
        <span style={{ zIndex: above(SIDEBAR) }}>
          <GreenSelect
            name={selectName}
            options={getSelectOptions(user)}
            isSearchable={false}
            initialOption={initialScoreOption}
            maxMenuHeight={120}
            trackingData={trackingData}
          />
        </span>
        {selectedScoreType && (
          <Flex w={1} align="center" style={{ minHeight: "min-content" }}>
            <ScoreView
              selectedScoreType={selectedScoreType}
              trackingData={{
                ...trackingData,
                component: baseballCardComponentTypes[selectedScoreType],
              }}
              user={user}
            />
          </Flex>
        )}
      </div>
    );
  }
}
