import React from "react";
import PropTypes from "prop-types";
import {
  Anchor,
  Box,
  Button,
  Card,
  Grommet,
  Image,
  Layer,
  Text,
} from "grommet";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { CLOVERLEAF_PLAN_DERIVED_STATUS, EVENT } from "../../utils/constants";
import { PricingModal } from "../../v2/PricingModal";
import { ComponentPreferenceView } from "../Dashboard/utils";
import { AnchorLink } from "@/components/v2/AnchorLink";
import { useLocale } from "@/lib/hooks";
import { Trans } from "@/lib/hooks/useLocale";

function PaywallEnforcerModal({
  isPlanOwner = false,
  viewType,
  derivedStatus,
  targetRef = undefined,
}) {
  const { t } = useLocale();
  const isInactiveSubscription = derivedStatus === CLOVERLEAF_PLAN_DERIVED_STATUS.INACTIVE;
  const isTrialExpired = derivedStatus === CLOVERLEAF_PLAN_DERIVED_STATUS.TRIAL_EXPIRED;
  const isEngagementExpired = derivedStatus === CLOVERLEAF_PLAN_DERIVED_STATUS.ENGAGEMENT_EXPIRED;
  const ownerMessage = viewType === ComponentPreferenceView.Team?
    t("paywall-enforcer-modal.message.team.owner") : t("paywall-enforcer-modal.message.profile.owner");
  const defaultMessage = viewType === ComponentPreferenceView.Team?
    t("paywall-enforcer-modal.message.team.default") : t("paywall-enforcer-modal.message.profile.default");

  const [isPricingModalOpen, setIsPricingModalOpen] = React.useState(false);

  const paywallEnforcerComponent = (
    <Card align="center" gap="medium" background="white" pad="large">
      <Image
        src="https://cloverleaf-app-publicfiles.s3.amazonaws.com/graphics/cloverleaf-paywall-lock.png"
        alt={t("paywall-enforcer-modal.imageAlt")}
      />
      <Text weight="bold" size="medium" textAlign="center">
        {isInactiveSubscription && t("paywall-enforcer-modal.header.inactive")}
        {isTrialExpired && t("paywall-enforcer-modal.header.expired")}
        {isEngagementExpired && t("paywall-enforcer-modal.header.engagement-expired")}
      </Text>
      {isInactiveSubscription && viewType === ComponentPreferenceView.Team && (
        <Text textAlign="center">
          <Trans i18nKey="common:paywall-enforcer-modal.inactive-message.team" t={t}>
            The team dashboard is available with an active subscription. Let your admin know you want to continue using Cloverleaf or
            <Anchor href="https://cloverleaf.me/getstarted" target="_blank" rel="noreferrer">
              get in touch with our sales team
            </Anchor>
            to learn about available plan options
          </Trans>
        </Text>
      )}
      {isInactiveSubscription && viewType === ComponentPreferenceView.Profile && (
        <Text textAlign="center">
          <Trans i18nKey="common:paywall-enforcer-modal.inactive-message.profile" t={t}>
            Team Profiles are available with an active subscription. Let your admin know you want to continue using Cloverleaf or
            <Anchor href="https://cloverleaf.me/getstarted" target="_blank" rel="noreferrer">
              get in touch with our sales team
            </Anchor>
            to learn about available plan options.
          </Trans>
        </Text>
      )}
      {isEngagementExpired && (
        <Box align="center" gap="medium">
          <Text>{t("paywall-enforcer-modal.engagement-expired-message")}</Text>
          <Button
            label={t("activate-subscription")}
            onClick={() => setIsPricingModalOpen(true)}
            primary
            uppercase
          />
          <AnchorLink
            href="//cloverleaf.me/contact-us"
            target="_blank"
            label={t("contact")}
            uppercase
          />
        </Box>
      )}
      {isTrialExpired && (
        isPlanOwner ? (
          <Box gap="medium">
            <Text textAlign="center">
              { ownerMessage }
            </Text>
            <Button
              label={t("paywall-enforcer-modal.button-label")}
              onClick={() => setIsPricingModalOpen(true)}
              primary
            />
            {isPricingModalOpen && (
              <PricingModal
                onClose={() => setIsPricingModalOpen(false)}
                trackingEvent={EVENT.TrialBannerPurchaseClicked.name}
              />
            )}
          </Box>
        ) : (
          <Text textAlign="center">{defaultMessage}</Text>
        )
      )}
    </Card>
  );

  if (viewType === ComponentPreferenceView.Team) {
    return (
      <Grommet theme={themeStyle}>
        <Layer
          background="transparent"
          animation={false}
          target={targetRef?.current}
          position="top"
          responsive={false}
          top="25vh"
        >
          {paywallEnforcerComponent}
        </Layer>
      </Grommet>
    );
  }

  if (viewType === ComponentPreferenceView.Profile) {
    return (
      // Match width for UserProfile on /p
      <Grommet theme={themeStyle} style={{ maxWidth: "1260px", width: "100%" }}>
        {paywallEnforcerComponent}
      </Grommet>
    );
  }

  return null;
}

PaywallEnforcerModal.displayName = "PaywallEnforcerModal";

PaywallEnforcerModal.propTypes = {
  isPlanOwner: PropTypes.bool,
  viewType: PropTypes.number.isRequired,
  derivedStatus: PropTypes.oneOf(["ACTIVE", "INACTIVE", "TRIAL_EXPIRED"]).isRequired,
  targetRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
};

export default PaywallEnforcerModal;
