import React from "react";
import {
  number,
  shape,
  string,
  func
} from "prop-types";
import Link from "next/link";
import styled from "styled-components";
import { Grommet } from "grommet";
import { Message } from "@/cloverleaf-ui/components";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { publicUserPropType, trackingDataProp } from "../../../lib/graphql/propTypes";
import {
  Box,
  Flex,
  TextLabel,
  Pagination,
  Text,
  TextButton,
  LoadingIndicator
} from "../../ui";
import Avatar from "../../ui/Avatar";
import { ResponseStatus } from "../../utils/constants";
import { WERE_SORRY_GONE_WRONG, VIEW_PROFILE } from "../../utils/strings/index";
import {
  WHITE,
  DEFAULT_FONT,
  GREEN,
  MEDIUM_SIZE
} from "../../utils/theme";
import UnauthorizedProfilePrompt from "../ProfilePage/UnauthorizedProfilePrompt";
import ScoreAndSelect from "./ScoreAndSelect";
import { INSIGHTS_SELECT_NAME } from "./UserDetails";
import { UserDetailsContainer } from "./UserDetailsContainer";
import { getCharacteristics, getProfile } from "./userStatsCard.utils";

export const SELECT_NAME = "UserStatsCardScoreType";

const ModalMainContent = styled(Flex)`
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  max-height: calc(95vh - 116px);

  @media (min-height: 650px){
    max-height: calc(85vh - 116px);
  }

  @media (min-height: 320px){
      min-height: 210px;
    }
`;

export default class UserStatsCard extends React.PureComponent {
  static propTypes = {
    initialPage: number,
    data: shape({
      profile: shape({
        status: string,
        user: publicUserPropType,
      }),
    }),
    clearSelects: func.isRequired,
    closeModal: func.isRequired,
    selectedTeam: shape({}),
    initialScoreType: string,
    selectedScoreType: string,
    source: string, // Where the stats card was opened from
    router: shape({ push: func.isRequired }).isRequired,
    trackingData: trackingDataProp,
  }

  static defaultProps = {
    initialPage: 1,
    data: {},
    selectedTeam: undefined,
    initialScoreType: undefined,
    selectedScoreType: undefined,
    source: undefined,
    trackingData: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.initialPage,
    };
  }

  componentWillUnmount() {
    this.props.clearSelects([SELECT_NAME, INSIGHTS_SELECT_NAME]);
  }

  changePage = index => this.setState({ currentPage: index });

  render() {
    const {
      selectedScoreType, data, initialScoreType, closeModal, selectedTeam, source, router, trackingData,
    } = this.props;

    if (data.loading) {
      return (
        <Flex justify="center" align="center" p={3} w={1} h={200}>
          <LoadingIndicator delayEnter={300} />
        </Flex>
      );
    }

    if (data.error) {
      return <Flex p={3} w={1} justify="center"><Text>{WERE_SORRY_GONE_WRONG}</Text></Flex>;
    }

    const { user, status } = getProfile(data);

    if (status === ResponseStatus.UNAUTHORIZED) {
      return (
        <UnauthorizedProfilePrompt user={user} onClick={() => router.push("/teams").then(closeModal)} />
      );
    }

    if (status === ResponseStatus.NOT_FOUND) {
      return (
        <Grommet theme={themeStyle}>
          <Message kind="warning" label="User not found" link={{ label: "Close", onClick: closeModal }} />
        </Grommet>
      );
    }

    if (!user) {
      return null;
    }

    const { currentPage } = this.state;
    const characteristics = getCharacteristics(user);

    return (
      <Flex column h={1}>
        <Flex h={66} shrink={0} w={1} background={GREEN}>
          <Box p={1}>
            <Avatar size={5} user={user} expandable={false} />
          </Box>
          <Flex column pt={1} pb={1} justify="space-around">
            <Flex align="center">
              <Flex maxWidth="225px" maxHeight="40px">
                <TextLabel whiteSpace="nowrap" style={{ overflow: "hidden" }} textOverflow="ellipsis" extraLarge color={WHITE} font={DEFAULT_FONT}>
                  {user.fullName}
                </TextLabel>
              </Flex>
              <Flex ml="6px">
                <Link href={{ pathname: "/p", query: { profileId: user.profile?.id } }} as={user.profile?.relativePublicUrl} passHref legacyBehavior>
                  <TextButton
                    white
                    style={{ fontSize: MEDIUM_SIZE }}
                    font={DEFAULT_FONT}
                    onClick={closeModal}
                  >
                    {VIEW_PROFILE}
                  </TextButton>
                </Link>
              </Flex>
            </Flex>
            {characteristics && <TextLabel large color={WHITE} font={DEFAULT_FONT}>{characteristics}</TextLabel>}
          </Flex>
        </Flex>
        <Flex pt={1} pl={1} pr={1} pb={0} h={1} column>
          <ModalMainContent column>
            <Flex column>
              <UserDetailsContainer
                {...{ user, selectedTeam, source }}
                visible={currentPage === 0}
                trackingData={trackingData}
              />
              <ScoreAndSelect
                {...{
                  user, selectedScoreType, initialScoreType, selectName: SELECT_NAME, selectedTeam, source,
                }}
                trackingData={trackingData}
                visible={currentPage === 1}
              />
            </Flex>
          </ModalMainContent>
        </Flex>
        <Flex shrink={0} pt="4px" column h="32px">
          <Pagination
            pages={2}
            selectedIndex={currentPage}
            onClick={this.changePage}
            dotMargin="4px"
          />
        </Flex>
      </Flex>
    );
  }
}
