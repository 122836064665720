import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import {
  contentDebugFragmentUserTrait,
  contentDebugFragmentTraitInterface,
  contentDebugFragmentEnneagramSecondaryTrait,
} from "./fragments";

export const publicUserScoresFragment = gql`
  fragment PublicUserScoresFragment on Scores {
    insightsDiscovery {
      details {
        id
        status
        createdAt
        apiEnabled
      }
    }
    personality {
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
        characteristics
      }
      traits {
        id
        name
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentTraitInterface
        value
      }
    }
    disc {
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      traits {
        id
        name
        secondaryName
        value
        description
        ...ContentDebugFragmentTraitInterface
      }
      tti {
        wheel
      }
    }
    instinctiveDrives {
      isSearching
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentUserTrait
        value
      }
      traits {
        id
        name
        nameKey
        secondaryName
        description
        ...ContentDebugFragmentUserTrait
        value
      }
      isSearching
    }
    strengths {
      title {
        id
        name
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentTraitInterface
      }
      traits {
        id
        name
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentTraitInterface
        order
      }
    }
    via {
      title {
        id
        name
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentTraitInterface
      }
      traits {
        id
        name
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentTraitInterface
        order
      }
    }
    culture {
      traits {
        id
        name
        nameKey
        description
        ...ContentDebugFragmentTraitInterface
        value
        order
      }
    }
    motivatingvalues {
      traits {
        id
        name
        nameKey
        description
        ...ContentDebugFragmentTraitInterface
        value
        order
      }
    }
    drivingforces {
      traits: groupedTraits {
        name
        secondaryName
        scores {
          name
          value
          description
        }
      }
    }
    enneagram {
      graphic
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      triad {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentEnneagramSecondaryTrait
        type
      }
      wing {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      growth {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentEnneagramSecondaryTrait
        type
      }
      stress {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentEnneagramSecondaryTrait
        type
      }
    }
    energyRhythm {
      graphic
      title {
        id
        name
        secondaryName
        secondaryNameKey
        description
        ...ContentDebugFragmentUserTrait
      }
      traits {
        id
        name
        value
        secondaryName
        description
        ...ContentDebugFragmentUserTrait
      }
    }
    strengthscope {
      graphic
      isPremium
      title {
        id
        name
        secondaryName
        description
        ...ContentDebugFragmentTraitInterface
      }
      traits {
        id
        name
        secondaryName
        secondaryNameKey
        iconUrl
        descriptions {
          label
          message
          isPremium
        }
      }
    }
  }
  ${contentDebugFragmentUserTrait}
  ${contentDebugFragmentTraitInterface}
  ${contentDebugFragmentEnneagramSecondaryTrait}
`;

export const publicUserFragment = gql`
  fragment PublicUser on PublicUser {
    id
    email
    firstName
    lastName
    fullName
    avatar
    role
    isSameOrg
    companyName
    insights {
      id
      personality
      type
      target
      message
    }
    scores {
      ...PublicUserScoresFragment
    }
    isTTI
    configuration {
      id
      configurationId
      name
      setting
      assessmentName
    }
    profile {
      id
      publicUrl
      relativePublicUrl
    }
    attributes {
      key
      label
    }
  }
  ${publicUserScoresFragment}
`;

export const publicUserQuery = gql`
  query publicUserQuery($userId: ID, $profileId:String) {
    profile(profileId: $profileId) {
      status,
      message,
      user {
        isInCoachingNetwork
        canBeViewedByCurrentUser
        privacy {
          status
        }
        ...PublicUser
      }
    }
    profileComponentPreferences(userId: $userId, profileId: $profileId) {
      status
      message
      componentPreferences {
        userId
        viewType
        preferences {
          componentId
          componentRank
          collapsed
          isCollapsible
          colSpan
        }
      }
    }
  }
  ${publicUserFragment}
`;

export const withPublicUser = graphql(publicUserQuery, {
  options: ({ profileId, userId }) => ({
    variables: { profileId, userId },
    errorPolicy: "all",
  }),
});
