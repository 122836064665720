import React from "react";
import { arrayOf } from "prop-types";
import map from "lodash/fp/map";
import { traitProp } from "../../../../lib/graphql/propTypes";
import { Box } from "../../../ui";
import TextBlock from "../../../ui/styled/TextBlock";
import ToggleButton from "../../ToggleButton";
import LabeledSpectrum from "../LabeledSpectrum";
import ThinSpectrum from "../ThinSpectrum";

const getSpectrumRange = map("value");
const getDescriptions = map("description");
const getLabels = map("name");

class DrivingForcesTraits extends React.PureComponent {
  static propTypes = {
    traits: arrayOf(traitProp).isRequired,
  };

  getTraitDetails = ({ name, scores }, range) => {
    const labels = getLabels(scores);
    const [firstDescription, secondDescription] = getDescriptions(scores);
    const [firstLabel, secondLabel] = labels;

    return (
      <React.Fragment>
        <LabeledSpectrum
          title={name}
          labels={labels}
          range={range}
        />
        <TextBlock>
          {firstLabel} - {firstDescription}
          <br /> <br /> {/* Can't nest components in a <p /> without throwing errors */}
          {secondLabel} - {secondDescription}
        </TextBlock>
      </React.Fragment>
    );
  }

  render() {
    const { traits } = this.props;

    return (
      <React.Fragment>
        {traits.map(({ name, secondaryName, scores }) => {
          const range = getSpectrumRange(scores);

          return (
            <Box w={1} key={name} mt={1}>
              <ToggleButton
                small
                title={name}
                subtitle={secondaryName}
                details={this.getTraitDetails({ name, scores }, range)}
                unexpandedDetails={<ThinSpectrum range={range} />}
              />
            </Box>
          );
        })}
      </React.Fragment>
    );
  }
}

export default DrivingForcesTraits;
