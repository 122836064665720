import React from "react";
import PropTypes from "prop-types";
import { useQuery, gql } from "@apollo/client";
import PaywallEnforcerModal from "./PaywallEnforcerModal";
import { Configuration } from "@/components/utils/constants";

const currentUserPlgPlanQuery = gql`
  query currentUserPlgPlan {
    currentUser {
      id
      plgPlan {
        isActive
        inTrial
        trialDaysLeft
        isPlanOwner
        planId
        subscriptionId
        derivedStatus
      }
      isPLG
      hasPaywallAccess(configurationId: "${Configuration.TEAM_DASHBOARD}")
    }
  }
`;

function PaywallEnforcerContainer({
  viewType = undefined,
  targetRef = undefined,
}) {
  const [paywallTargetRef, setPaywallTargetRef] = React.useState(undefined);

  /**
   * Delay paywall render so that repaint has time to
   * fill the dom before the overlay is applied.
   */
  React.useEffect(() => {
    let timer;

    if (targetRef) {
      timer = setTimeout(() => setPaywallTargetRef(targetRef), 1000);
    }

    return () => clearTimeout(timer);
  }, [targetRef]);

  const { data, loading } = useQuery(currentUserPlgPlanQuery);

  if (loading || data?.currentUser?.hasPaywallAccess) {
    return null;
  }

  const { isPlanOwner, derivedStatus } = data?.currentUser?.plgPlan || {};

  return (
    <PaywallEnforcerModal
      targetRef={paywallTargetRef}
      isPlanOwner={isPlanOwner}
      viewType={viewType}
      derivedStatus={derivedStatus}
    />
  );
}

PaywallEnforcerContainer.propTypes = {
  targetRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
  viewType: PropTypes.number,
};

export default PaywallEnforcerContainer;
