import React from "react";
import { Flex, Box } from "../../../ui";
import { ASSESSMENT, GREEN } from "../../../utils/theme";
import { useContentManager } from "../../ContentManager";
import TitleToggleContainer from "../../TitleToggle";
import EnergyRhythmTraits from "./EnergyRhythmTraits";

function EnergyRhythmResults({ startExpanded = false, data, trackingData }) {
  const { wrapContent } = useContentManager();
  const getTitleColor = (title) => {
    switch (title) {
      case "Morning Type":
        return ASSESSMENT.ENERGYRHYTHM.STARTER;
      case "Mid-day Type":
        return ASSESSMENT.ENERGYRHYTHM.PACER;
      case "Evening Type":
        return ASSESSMENT.ENERGYRHYTHM.ANCHOR;
      default:
        return GREEN;
    }
  };

  const { title = {}, traits = [] } = data || {};

  return (
    <Flex w={1} p={2} align="flex-start" wrap column>
      <Box w={1}>
        <TitleToggleContainer
          title={title.name}
          description={wrapContent(title)}
          startExpanded={startExpanded}
          color={getTitleColor(title.secondaryNameKey)}
          trackingData={trackingData}
        />
      </Box>
      <EnergyRhythmTraits traits={traits} trackingData={trackingData} />
      <Flex justify="center" w={1} p={1}>
        <Flex w={1} p={1}>
          <img width="100%" height="100%" src={data.graphic} alt="Energy Rhythm" />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default EnergyRhythmResults;
