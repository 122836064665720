import React from "react";
import get from "lodash/fp/get";
import { Flex, Box } from "../../../ui";
import InstinctiveDrivesGraph from "../../../ui/InstinctiveDrivesGraph";
import { buildInstinctiveDrivesFromUserTraits, SpectrumLayout } from "../../../ui/InstinctiveDrivesGraph/instinctiveDrivesGraph.utils";
import { useContentManager } from "../../ContentManager";
import InstinctiveDrivesTraits from "../../InstinctiveDrives/InstinctiveDrivesTraits";
import TitleToggleContainer from "../../TitleToggle";
import InstinctiveDrivesSearchingResults from "./InstinctiveDrivesSearchingResults";

function InstinctiveDrivesResultsContainer({ data, trackingData }) {
  const { wrapContent } = useContentManager();
  const title = get("title", data);
  const traits = get("traits", data);
  const isSearching = get("isSearching", data);

  if (isSearching) {
    return (
      <Flex w={1} p={2} align="flex-start" wrap column>
        <InstinctiveDrivesSearchingResults />
      </Flex>
    );
  }

  return (
    <Flex w={1} p={2} align="flex-start" wrap column>
      {title && (
        <Box w={1}>
          <TitleToggleContainer
            title={`I.D. ${get("name", title)}`}
            description={wrapContent(title)}
            trackingData={trackingData}
          />
        </Box>
      )}
      {traits && <InstinctiveDrivesTraits traits={traits} trackingData={trackingData} />}
      {traits && (
        <Box mt={2}>
          <InstinctiveDrivesGraph
            instinctiveDrives={buildInstinctiveDrivesFromUserTraits(traits)}
            layout={SpectrumLayout.ME}
          />
        </Box>
      )}
    </Flex>
  );
}

export default InstinctiveDrivesResultsContainer;
