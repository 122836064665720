import React from "react";
import {
  CONDENSED_FONT,
  MEDIUM_SIZE,
  WHITE,
  GREY,
  LIGHT_BLUE,
  GREEN
} from "../utils/theme";
import SelectContainer from "./SelectContainer";

const selectStyles = {
  placeholder: base => ({
    ...base,
    color: GREEN,
    fontFamily: CONDENSED_FONT,
    fontSize: MEDIUM_SIZE,
    textTransform: "uppercase",
  }),
  valueContainer: base => ({
    ...base,
    padding: 0,
    margin: 0,
    boxShadow: "none",
  }),
  control: base => ({
    ...base,
    backgroundColor: WHITE,
    color: GREEN,
    border: "none",
    borderRadius: "4px",
    boxShadow: "none",
    minWidth: "140px",
    minHeight: "unset",
  }),
  option: (base, state) => ({
    ...base,
    color: GREEN,
    border: 0,
    fontFamily: CONDENSED_FONT,
    fontSize: MEDIUM_SIZE,
    backgroundColor: state.isFocused ? LIGHT_BLUE : WHITE,
    textTransform: "uppercase",
  }),
  indicatorSeparator: base => ({
    ...base,
    visibility: "hidden",
  }),
  menu: base => ({
    ...base,
    margin: 0,
    padding: 0,
    border: `1px solid ${GREY}`,
    borderRadius: "0px 0px 4px 4px",
    backgroundColor: WHITE,
    boxShadow: "0 4px 11px hsla(0, 0%, 0%, 0.1)", // Base box shadow without border as shadow
  }),
  singleValue: base => ({
    ...base,
    color: GREEN,
    fontSize: MEDIUM_SIZE,
    fontFamily: CONDENSED_FONT,
    border: 0,
    textTransform: "uppercase",
  }),
};

class GreenSelect extends React.PureComponent {
  render() {
    return (
      <SelectContainer
        {...this.props}
        styles={selectStyles}
        isSearchable={false}
      />
    );
  }
}

export default GreenSelect;
