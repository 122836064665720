import React from "react";
import {
  arrayOf,
  bool,
  string
} from "prop-types";
import castArray from "lodash/fp/castArray";
import isEmpty from "lodash/fp/isEmpty";
import {
  Box,
  Flex,
  Text,
  TextLabel
} from "../ui";
import {
  DARK_GREY,
  DEFAULT_FONT,
  GREY
} from "../utils/theme";

function TraitTipsSection({
  isSecondary = false,
  traitTips = [],
  emptyMessage = null,
}) {
  if (isEmpty(traitTips)) {
    return emptyMessage && (
      <Flex w={1} h={1} align="center">
        <Text color={DARK_GREY} center>{emptyMessage}</Text>
      </Flex>
    );
  }

  return (
    <Box style={isSecondary ? { paddingLeft: "8px", borderLeft: `1px solid ${GREY}` } : { paddingRight: "8px" }}>
      {castArray(traitTips).map(tip => (
        <Box mt={2} key={tip}>
          <TextLabel style={{ fontFamily: DEFAULT_FONT, fontSize: "16px" }}>{tip}</TextLabel>
        </Box>
      ))}
    </Box>
  );
}

TraitTipsSection.displayName = "TraitTipsSection";

TraitTipsSection.propTypes = {
  isSecondary: bool,
  traitTips: arrayOf(string.isRequired),
  emptyMessage: string,
};

export default TraitTipsSection;
