import React from "react";
import {
  bool,
  string,
  shape
} from "prop-types";
import { useQuery, gql } from "@apollo/client";
import getOr from "lodash/fp/getOr";
import { LoadingIndicator } from "../../../../../ui";
import { getId } from "../../../../../utils";
import { ASSESSMENT } from "../../../../../utils/constants";
import UserComparisonThinkingStyleResults from "./UserComparisonThinkingStyleResults";

const getUserCompatibility = getOr({}, "userCompatibility");

const userCompatibilityQuery = gql`
  query userCompatibilityQuery(
    $userId: ID
    $toUserId: ID
    $skipAnalytics: Boolean
    $assessment: AssessmentName!
  ) {
    userCompatibility(
      userId: $userId
      toUserId: $toUserId
      skipAnalytics: $skipAnalytics
      assessment: $assessment
    ) {
      value
      title {
        name
        description
      }
      content {
        paragraph1
        paragraph2
      }
      primaryUser: user {
        id
        fullName
        traitTips(assessment: $assessment)
        scores {
          disc {
            title {
              id
            }
          }
          personality {
            title {
              id
            }
          }
        }
      }
      secondaryUser: toUser {
        id
        fullName
        traitTips(assessment: $assessment)
        scores {
          disc {
            title {
              id
            }
          }
          personality {
            title {
              id
            }
          }
        }
      }
      range {
        min {
          label
          value
        }
        max {
          label
          value
        }
      }
    }
  }
`;

function UserComparisonThinkingStyleResultsContainer({
  hideUserSection = false, primaryUser, secondaryUser, defaultRelationship = false, assessment,
}) {
  const userId = getId(primaryUser);
  const toUserId = getId(secondaryUser);
  const { data, loading, error } = useQuery(userCompatibilityQuery, {
    variables: {
      userId,
      toUserId,
      skipAnalytics: defaultRelationship,
      assessment,
    },
    skip: !userId || !toUserId || !assessment, // don't query if we don't have both users
  });
  if (error) return null;
  if (loading) return <LoadingIndicator />;

  const userCompatibility = getUserCompatibility(data);

  return (
    <UserComparisonThinkingStyleResults
      value={userCompatibility.value}
      content={userCompatibility.content}
      range={userCompatibility.range}
      title={userCompatibility.title}
      primaryUser={userCompatibility.primaryUser}
      secondaryUser={userCompatibility.secondaryUser}
      hideUserSection={hideUserSection}
      showUserComparisonChart={assessment !== ASSESSMENT.ENNEAGRAM}
    />
  );
}

UserComparisonThinkingStyleResultsContainer.propTypes = {
  hideUserSection: bool,
  primaryUser: shape({ id: string.isRequired }),
  secondaryUser: shape({ id: string.isRequired }),
  defaultRelationship: bool,
  assessment: string.isRequired,
};

export default UserComparisonThinkingStyleResultsContainer;
