import React from "react";
import { Box } from "../../../ui";
import TextBlock from "../../../ui/styled/TextBlock";
import { useContentManager } from "../../ContentManager";
import ToggleButton from "../../ToggleButton";
import PersonalitySpectrum from "./PersonalitySpectrum";

function PersonalityTraits({ traits, trackingData }) {
  const { wrapContent } = useContentManager();
  const getTraitDetails = (trait, index) => (
    <React.Fragment>
      {trait.value ?
        <PersonalitySpectrum trait={trait} index={index} />
        : null}
      <TextBlock>{wrapContent(trait)}</TextBlock>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      {traits.map((trait, index) => (
        <Box w={1} key={trait.name} mt={1}>
          <ToggleButton
            title={trait.secondaryName}
            subtitle={trait.value || null}
            details={getTraitDetails(trait, index)}
            trackingData={trackingData}
          />
        </Box>
      ))}
    </React.Fragment>
  );
}

export default PersonalityTraits;
