import React from "react";
import {
  number,
  bool,
  shape,
  string
} from "prop-types";
import withTranslation from "next-translate/withTranslation";
import styled from "styled-components";
import { remap } from "../utils";
import {
  BLACK,
  GREEN,
  DARK_GREY
} from "../utils/theme";
import {
  Flex,
  TextLabel,
  Box
} from ".";

const circleSize = 24;

const calcPlottedPosition = (value, range) => remap({
  value,
  currentMin: range.min.value,
  currentMax: range.max.value,
  newMin: 1, // 1 instead of a zero here so the plot point doesn't hug the border
  newMax: 100,
});

const PlottedCircle = styled.span`
  position: absolute;
  width: ${circleSize}px;
  height: ${circleSize}px;
  background-color: ${props => (props.grayscale ? DARK_GREY : GREEN)};
  border-radius: 50%;
  bottom: 0px;
  top: 4px;
  left: ${props => `${calcPlottedPosition(props.value, props.range)}%`};
  transition: all 0.5s ease-in-out;
  transform: translateX(-50%);
`;

const PlottedCircleContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: ${circleSize / 2}px;
  right: ${circleSize / 2}px;
  height: 20px;
`;

const ThinkingStyleContainer = styled.div`
  width: 100%;
  height: 28px;
  border-radius: 10px;
  border: 2px solid ${BLACK};
`;

class HorizontalLineChart extends React.PureComponent {
  static propTypes = {
    value: number,
    grayscale: bool,
    range: shape({
      min: shape({ value: number.isRequired, label: string.isRequired }).isRequired,
      max: shape({ value: number.isRequired, label: string.isRequired }).isRequired,
    }),
  };

  static defaultProps = {
    value: undefined,
    grayscale: false,
    range: undefined,
  };

  render() {
    const { value, range: _range, grayscale } = this.props;

    const range = {
      min: {
        label: this.props.i18n.t("diverse"), value: 0,
      },
      max: {
        label: this.props.i18n.t("similar"), value: 100,
      },
    };

    return (
      <Flex column px={1}>
        <Box position="relative">
          <ThinkingStyleContainer>
            <PlottedCircleContainer>
              <PlottedCircle
                value={value ?? 50} // Allow zero values, but null or undefined sets plot point to the middle
                grayscale={grayscale || !range}
                range={range}
              />
            </PlottedCircleContainer>
          </ThinkingStyleContainer>
        </Box>
        {range && (
          <Flex mb={1} justify="space-between" mt={1} w={1}>
            <TextLabel large uppercase style={{ cursor: "default" }}>{range.min.label}</TextLabel>
            <TextLabel large uppercase style={{ cursor: "default" }}>{range.max.label}</TextLabel>
          </Flex>
        )}
      </Flex>
    );
  }
}

export default withTranslation(HorizontalLineChart, "common");
