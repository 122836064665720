import { withRouter } from "next/router";
import compose from "lodash/fp/compose";
import get from "lodash/fp/get";
import { connect } from "react-redux";
import { withPublicUser } from "../../../lib/graphql/publicUser";
import {
  closeModal,
  getModalValues,
  getSelectValue,
  clearSelects,
  getSelectedTeam
} from "../../../modules/ui";
import UserStatsCardComponent from "./UserStatsCardComponent";

const getSelectedScoreType = state =>
  (get("value", getSelectValue(state, "UserStatsCardScoreType")));

const mapStateToProps = state => ({
  selectedScoreType: getSelectedScoreType(state),
  userId: getModalValues(state, "userId"),
  profileId: getModalValues(state, "profileId"),
  initialPage: getModalValues(state, "initialPage"),
  initialScoreType: getModalValues(state, "initialScoreType"),
  source: getModalValues(state, "source"),
  selectedTeam: getSelectedTeam(state),
  trackingData: getModalValues(state, "trackingData"),
});

const mapDispatchToProps = { closeModal, clearSelects };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPublicUser,
  withRouter,
)(UserStatsCardComponent);
