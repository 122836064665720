/****************************************************************
 * Used to port existing pages to 2023 app layout (w/ side nav & new header)
 * For NEW PAGES, use components/v2/Layout/Layout.js
 * ui/NewLayout to replace ui/Layout in standard pages.
 */

import React from "react";
import styled from "styled-components";
import {
  Box,
  Grid,
  Grommet
} from "grommet";
import { getThemeColor, themeStyle } from "@/cloverleaf-ui/theme";
import Modal from "../../composite/Modal";
import ErrorBoundary from "../ErrorBoundary";
import { Flex } from "../FlexBox";
import { ComponentPreferenceView } from "@/components/composite/Dashboard/utils";
import PaywallEnforcerContainer from "@/components/composite/PaywallEnforcer";
import { Header } from "@/components/v2/Layout/components/Header";
import { Sidebar } from "@/components/v2/Layout/components/Sidebar";
import { SidebarProvider } from "@/components/v2/Layout/components/Sidebar.context";

const Wrapper = styled(Flex)`
  height: 100vh;
`;

function NewLayout({
  background = "background-light-blue",
  children,
  navbar = false,
  view = undefined,
  w = "auto",
}) {
  const contentRef = React.useRef(null);

  const content = (
    <Wrapper column justify="space-between" align="center" w={w} id="content-wrapper">
      <Modal />
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Wrapper>
  );

  return (
    <Grid
      fill
      height="100vh"
      rows={["auto", "flex"]}
      columns={["auto", "flex"]}
      areas={[
        ["header", "header"],
        ["sidebar", "main"],
      ]}
    >
      {navbar && (
        <SidebarProvider>
          <Box gridArea="header">
            <Grommet gridArea="header" theme={themeStyle} style={{ width: "100%" }}>
              <Header showSidebar />
            </Grommet>
          </Box>
          <Box gridArea="sidebar" style={{ zIndex: 1 }}>
            <Grommet gridArea="sidebar" theme={themeStyle}>
              <Sidebar />
            </Grommet>
          </Box>
        </SidebarProvider>
      )}
      {(view === ComponentPreferenceView.Team || view === ComponentPreferenceView.CoachingView) &&
        <PaywallEnforcerContainer targetRef={contentRef} viewType={ComponentPreferenceView.Team} />
      }
      <Box
        ref={contentRef}
        align="center"
        background={getThemeColor(background)}
        gridArea="main"
        height="100%"
        pad="medium"
        style={{
          overflowY: "scroll",
          zIndex: 0,
        }}
      >
        {content}
      </Box>
    </Grid>
  );
}

export default NewLayout;
