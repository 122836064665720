import React from "react";
import { shape } from "prop-types";
import get from "lodash/fp/get";
import getOr from "lodash/fp/getOr";
import { track } from "../../../../../lib";
import { discScore, trackingDataProp } from "../../../../../lib/graphql/propTypes";
import { Flex } from "../../../../ui";
import { discTitle } from "../../../../utils";
import { EVENT } from "../../../../utils/constants";
import DiscWheel from "../../../DiscWheel";
import TitleToggleContainer from "../../../TitleToggle";
import DiscTraits from "../DiscTraits";
import { useContentManager } from "@/components/composite/ContentManager";

function FullDiscResults({
  user,
  trackingData = undefined,
}) {
  const { wrapContent } = useContentManager();
  const title = get("scores.disc.title", user);
  const traits = getOr([], "scores.disc.traits", user);

  const handleClick = (onClick, param, traits) => () => {
    const { name: eventName, props: properties } = EVENT.ContentViewed;
    if (trackingData) {
      track(eventName, {
        ...trackingData,
        action: properties.action.open,
        contentType: properties.contentType.trait,
        element: traits.find(trait => param === trait.name)?.secondaryName,
      });
    }
    onClick(param)();
  };

  return (
    <React.Fragment>
      {title && (
        <Flex column align="inherit" mb={1}>
          <TitleToggleContainer
            title={discTitle({ user, title })}
            // description={title}
            description={wrapContent(title)}
            trackingData={trackingData}
          />
        </Flex>
      )}
      <DiscTraits traits={traits} trackingData={trackingData}>
        {({ onClick, toggledLetter }) => (
          <DiscWheel
            users={user}
            onClick={param => handleClick(onClick, param, traits)}
            selectedLetter={toggledLetter}
            trackingData={trackingData}
          />
        )}
      </DiscTraits>
    </React.Fragment>
  );
}

FullDiscResults.propTypes = {
  user: shape({
    scores: shape({
      disc: discScore,
    }),
  }).isRequired,
  trackingData: trackingDataProp,
};

export default FullDiscResults;
