import React from "react";
import { func, shape } from "prop-types";
import get from "lodash/fp/get";
import { connect } from "react-redux";
import styled from "styled-components";
import { track } from "../../../lib";
import { closeModal } from "../../../modules/ui";
import { withOutsideClick } from "../../hoc";
import { TextButton, Icon } from "../../ui";
import RoundedBox from "../../ui/styled/RoundedBox";
import { convertHexToRGBA } from "../../utils";
import { EVENT } from "../../utils/constants";
import { CLOSE_MODAL } from "../../utils/strings";
import {
  WHITE,
  BLACK,
  GREEN
} from "../../utils/theme";
import { MODAL } from "../../utils/zIndex";
import UserStatsCard from "../UserStatsCard";

const modals = {
  UserStatsCard: <UserStatsCard />,
};

const Dimmer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${convertHexToRGBA(BLACK, 0.6)};
  z-index: ${MODAL};
`;

const Modal = styled.div`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    top: 5%;

    max-height: 95vh;
    @media (min-height: 650px){
    top: 15%;
    max-height: 85vh;
    }

    @media (max-height: 410px){
    top: 0%;
    }

    @media (max-height: 380px){
    height: calc(100% - 8px);
    max-height: calc(100% - 8px);
    top: 0%;
    }
`;

const ModalBox = styled(RoundedBox)`
  overflow: hidden;
  border: 1px solid ${GREEN};
  margin-top: 8px;
  height: 100%;
`;

const CloseButton = styled(TextButton)`
  position: absolute;
  right: 8px;
  top: 5px;
  color: ${WHITE};
  font-size: 1.125rem;
`;

const ModalBoxWithOutsideClick = withOutsideClick(ModalBox);

class ModalContainer extends React.PureComponent {
  static propTypes = {
    closeModalProp: func.isRequired,
    modalData: shape({}).isRequired,
  }

  componentDidMount() {
    document.body.setAttribute("style", "overflow: hidden");
  }

  componentWillUnmount() {
    document.body.setAttribute("style", "");
  }

  render() {
    const { modalData, closeModalProp } = this.props;
    const { name: eventName, props: properties } = EVENT.ContentViewed;

    const handleClick = () => {
      if (modalData?.modalData?.trackingData) {
        track(eventName, {
          ...modalData.modalData.trackingData,
          action: properties.action.close,
        });
      }
      closeModalProp();
    };

    if (!modalData) {
      return null;
    }

    return (
      <Dimmer>
        <Modal>
          <ModalBoxWithOutsideClick column w={400} pt={0} pb={0} onOutsideClick={closeModalProp}>
            {modals[get("modalType", modalData)]}
            <CloseButton white onClick={handleClick}>
              <Icon icon={["fas", "times"]} tooltip={{ title: CLOSE_MODAL }} />
            </CloseButton>
          </ModalBoxWithOutsideClick>
        </Modal>
      </Dimmer>
    );
  }
}

function ModalRenderer(props) {
  if (!props.modalData) {
    return null;
  }

  return <ModalContainer {...props} />;
}

ModalRenderer.propTypes = {
  modalData: shape({}),
};

export default connect(state => ({ modalData: state.app.ui.modal }), { closeModalProp: closeModal })(ModalRenderer);
