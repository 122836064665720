import React from "react";
import {
  bool,
  shape,
  string
} from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/client";
import get from "lodash/fp/get";
import { track } from "../../../lib/analytics";
import { insightsQuery } from "../../../lib/graphql/insights";
import { publicUserPropType, trackingDataProp } from "../../../lib/graphql/propTypes";
import { currentUserIdQuery } from "../../hoc/withCurrentUserId";
import {
  ErrorMessage,
  Flex,
  LoadingIndicator
} from "../../ui";
import { EVENT } from "../../utils/constants";
import { ERROR_OCCURRED_TRY_AGAIN } from "../../utils/strings";
import { UserDetails } from "./UserDetails";

export function UserDetailsContainer({
  selectedTeam,
  source,
  user,
  visible = false,
  trackingData,
}) {
  const {
    loading: loadingCurrentUserId,
    error: queryError,
    data: currentUserData,
  } = useQuery(currentUserIdQuery, { errorPolicy: "all" });

  const [getInsights, {
    loading: loadingInsights,
    error: insightsQueryError,
    data: insightsData,
  }] = useLazyQuery(insightsQuery, { fetchPolicy: "cache-and-network" });

  React.useEffect(() => {
    if (selectedTeam && user) {
      track(EVENT.UserStatsCardViewed.name, {
        targetUserId: user?.id,
        view: EVENT.UserStatsCardViewed.props.view.userDetails,
        teamId: selectedTeam?.id,
        source,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (user?.id) {
      getInsights({
        variables: {
          userId: user?.id,
          target: "team",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingCurrentUserId || loadingInsights) {
    return (
      <Flex w={1} align="center" justify="center">
        <LoadingIndicator />
      </Flex>
    );
  }

  if (queryError || insightsQueryError) {
    return <ErrorMessage error={ERROR_OCCURRED_TRY_AGAIN} />;
  }

  const insights = get("insights", insightsData);
  const display = visible ? "block" : "none";

  return (
    <div style={{ display }}>
      <UserDetails
        currentUserId={currentUserData?.currentUser?.id}
        insights={insights}
        user={user}
        trackingData={trackingData}
      />
    </div>
  );
}

UserDetailsContainer.propTypes = {
  user: publicUserPropType.isRequired,
  selectedTeam: shape({ id: string }),
  source: string,
  visible: bool,
  trackingData: trackingDataProp,
};

export default UserDetailsContainer;
