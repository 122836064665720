import React from "react";
import {
  func,
  string,
  shape
} from "prop-types";
import {
  Flex,
  Box,
  Logo,
  TextLabel,
  Button
} from "@/components/ui";
import Layout from "@/components/ui/Layout";
import ModalBox from "@/components/ui/styled/ModalBox";
import { PageName } from "@/components/utils/constants";
import { useLocale } from "@/lib/hooks";

function UnauthorizedProfilePrompt({ user, onClick }) {
  const { t } = useLocale();

  return (
    <Layout navbar page={PageName.p}>
      <ModalBox column w={400} pt={0} pb={0}>
        <Flex p={2} justify="center" align="center" column>
          <Box mb={2} w={1 / 2}>
            <Logo />
          </Box>
          <Box>
            <TextLabel extraLarge>
              {t("unauthorized-profile.heading")}
            </TextLabel>
          </Box>
          <Box mt={2}>
            <TextLabel>
              {user?.name ?
                t("unauthorized-profile.message-with-user", { user: user.name })
                : t("unauthorized-profile.message")}
            </TextLabel>
          </Box>
          <Box mt={2}>
            <Button block primary onClick={onClick}>
              {t("unauthorized-profile.start-a-team")}
            </Button>
          </Box>
        </Flex>
      </ModalBox>
    </Layout>
  );
}

UnauthorizedProfilePrompt.propTypes = {
  onClick: func,
  user: shape({
    name: string,
  }),
};

export { UnauthorizedProfilePrompt };
