import React from "react";
import { Flex, Box } from "../../../ui";
import { useContentManager } from "../../ContentManager";
import TitleToggleContainer from "../../TitleToggle";
import ViaTraits from "./ViaTraits";

function ViaResults({ data, startExpanded = false, trackingData }) {
  const { wrapContent } = useContentManager();
  const { title, traits } = data;

  return (
    <Flex m={2} align="flex-start" wrap column>
      <Box w={1}>
        <TitleToggleContainer
          title={title.name}
          description={wrapContent(title)}
          startExpanded={startExpanded}
          trackingData={trackingData}
        />
      </Box>
      <ViaTraits traits={traits} startExpanded={startExpanded} trackingData={trackingData} />
    </Flex>
  );
}

export default ViaResults;
