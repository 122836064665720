import styled, { css } from "styled-components";
import { GREEN } from "../../utils/theme";
import RoundedBox from "./RoundedBox";

const getSize = ({ small, medium, large, xl }) => {
  if (small) {
    return "350px";
  }
  if (medium) {
    return "400px";
  }
  if (large) {
    return "550px";
  }
  if (xl) {
    return "768px";
  }

  return "400px";
};

const responsiveLargeModal = css`
  @media (max-width: 767px) {
    width: 320px;
  }
`;

const ModalBox = styled(RoundedBox)`
  overflow: hidden;
  width: ${props => getSize(props)};
  ${props => (!props.noBorder ? `border: 1px solid ${GREEN}` : "")};
  margin-top: 8px;
  height: 100%;
  min-height: 50px;
  max-height: 80vh;
  max-width: 100%;

  @media (max-width: 440px) {
    min-width: 320px;
    width: 100vw;
    overflow: scroll;
  }

  ${props => (props.large ? responsiveLargeModal : "")};
`;

export default ModalBox;
