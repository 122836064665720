import { shape, string } from "prop-types";
import { insightProp, traitProp } from "../../../../lib/graphql/propTypes";

export const enneagramSecondaryTraitProp = shape({
  id: string.isRequired,
  name: string.isRequired,
  secondaryName: string.isRequired,
  description: string.isRequired,
  type: string.isRequired,
});

export const enneagramTitleTraitProp = shape({
  id: string.isRequired,
  name: string.isRequired,
  secondaryName: string,
  description: string.isRequired,
  characteristics: string,
});

export const enneagramProp = shape({
  title: enneagramTitleTraitProp.isRequired,
  wing: traitProp,
  growth: enneagramSecondaryTraitProp,
  stress: enneagramSecondaryTraitProp,
  triad: enneagramSecondaryTraitProp,
  graphic: string,
  insight: insightProp,
});
