import React from "react";
import PropTypes from "prop-types";
import first from "lodash/fp/first";
import isEmpty from "lodash/fp/isEmpty";
import { track } from "../../../lib/analytics";
import {
  publicUserPropType,
  idType,
  trackingDataProp
} from "../../../lib/graphql/propTypes";
import {
  Flex,
  Text,
  TextLabel
} from "../../ui";
import {
  EVENT,
  INSIGHT_LABELS,
  ASSESSMENT
} from "../../utils/constants";
import { THINKING_STYLE_COMPARISON } from "../../utils/strings";
import { GREEN } from "../../utils/theme";
import GreenSelect from "../GreenSelect";
import UserComparisonThinkingStyleResults from "../TeamDashboard/ThinkingStyle/UserComparisonThinkingStyleNugget/UserComparisonThinkingStyleResults";

export const INSIGHTS_SELECT_NAME = "UserStatsCardInsightType";

export const getInsightOptions = insights =>
  Object.keys(insights).map(insight => ({ value: insight, label: INSIGHT_LABELS[insight] }));

export const getOneInsightOfEachType = allInsights =>
  allInsights.reduce((acc, insight) => (acc[insight.type] ? acc : { ...acc, [insight.type]: insight.message }), {});

export function UserDetails({
  currentUserId,
  insights,
  user,
  trackingData,
}) {
  const [selectedInsight, setSelectedInsight] = React.useState();

  React.useEffect(() => {
    if (selectedInsight) {
      track(EVENT.InsightViewed.name, {
        insightType: selectedInsight?.value,
        channel: EVENT.InsightViewed.props.channel.UserStatsCard,
        // TODO: Needs to be changed to read insights.target in the near future.
        isCoachingFocus: !!selectedInsight?.tags?.length,
        target: EVENT.InsightViewed.props.target.team,
        targetUserId: user?.id,
        userInsightId: selectedInsight?.id,
      });
    }
  }, [selectedInsight, user?.id]);

  const trackContentViewed = (value) => {
    track(EVENT.ContentViewed.name, {
      ...trackingData,
      element: value.label,
    });
  };

  const onChangeInsight = (value) => {
    if (trackingData) {
      trackContentViewed(value);
    }
    setSelectedInsight(value);
  };

  const renderInsights = () => {
    if (isEmpty(insights)) return null;

    const variedInsights = getOneInsightOfEachType(insights);
    const insightsOptions = getInsightOptions(variedInsights);
    const insight = selectedInsight || first(insightsOptions);

    return (
      <Flex column w={1} mb={1}>
        <GreenSelect
          name={INSIGHTS_SELECT_NAME}
          options={insightsOptions}
          isSearchable={false}
          maxMenuHeight={120}
          onChange={onChangeInsight}
          value={insight}
        />
        <Text style={{ margin: 0 }}>{variedInsights[insight?.value]}</Text>
      </Flex>
    );
  };

  return (
    <Flex column style={{ minHeight: "min-content" }}>
      {renderInsights()}
      { currentUserId && user?.id && user?.id !== currentUserId && (
        <Flex column w={1} mb={1} mt={7}>
          <TextLabel uppercase color={GREEN}>{THINKING_STYLE_COMPARISON}</TextLabel>
          <UserComparisonThinkingStyleResults
            primaryUser={{ id: currentUserId }}
            secondaryUser={user}
            hideUserSection
            assessment={ASSESSMENT.PERSONALITY}
          />
        </Flex>
      )}
    </Flex>
  );
}

UserDetails.propTypes = {
  user: publicUserPropType.isRequired,
  insights: PropTypes.array,
  currentUserId: idType,
  trackingData: trackingDataProp,
};

export default UserDetails;
