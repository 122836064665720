import React from "react";
import { trackingDataProp, traitProp } from "../../../../lib/graphql/propTypes";
import { Box } from "../../../ui";
import {
  STRESS,
  TRIAD,
  GROWTH,
  WING
} from "../../../utils/strings";
import {
  PURPLE,
  GREEN,
  YELLOW,
  DARK_GREEN
} from "../../../utils/theme";
import { useContentManager } from "../../ContentManager";
import ToggleButton from "../../ToggleButton";
import { enneagramSecondaryTraitProp } from "./enneagram.propTypes";

function EnneagramTraits({
  wing, growth, stress, triad, trackingData,
}) {
  const { wrapContent } = useContentManager();

  return (
    <React.Fragment>
      {wing && (
        <Box w={1} mt={1}>
          <ToggleButton
            title={wing.secondaryName}
            subtitle={WING}
            details={wrapContent(wing, {
              color: DARK_GREEN,
              border: true,
            })}
            flip
            small
            color={DARK_GREEN}
            trackingData={trackingData ? {
              element: wing.secondaryName,
              ...trackingData,
            } : undefined}
          />
        </Box>
      )}
      {growth && (
        <Box w={1} mt={1}>
          <ToggleButton
            title={`${growth.name}: ${growth.secondaryName}`}
            subtitle={GROWTH}
            details={wrapContent(growth, {
              color: PURPLE,
              border: true,
            })}
            flip
            small
            color={PURPLE}
            trackingData={trackingData ? {
              element: growth.secondaryName,
              ...trackingData,
            } : undefined}
          />
        </Box>
      )}
      {stress && (
        <Box w={1} mt={1}>
          <ToggleButton
            title={`${stress.name}: ${stress.secondaryName}`}
            subtitle={STRESS}
            details={wrapContent(stress, {
              color: YELLOW,
              border: true,
            })}
            flip
            small
            color={YELLOW}
            trackingData={trackingData ? {
              element: stress.secondaryName,
              ...trackingData,
            } : undefined}
          />
        </Box>
      )}
      {triad && (
        <Box w={1} mt={1}>
          <ToggleButton
            title={triad.name}
            subtitle={TRIAD}
            details={wrapContent(triad, {
              color: GREEN,
              border: true,
            })}
            flip
            small
            color={GREEN}
            trackingData={trackingData ? {
              element: triad.secondaryName,
              ...trackingData,
            } : undefined}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

EnneagramTraits.propTypes = {
  wing: traitProp,
  growth: enneagramSecondaryTraitProp,
  stress: enneagramSecondaryTraitProp,
  triad: enneagramSecondaryTraitProp,
  trackingData: trackingDataProp,
};

export default EnneagramTraits;
