import React from "react";
import { drivingForcesScore } from "../../../../lib/graphql/propTypes";
import { Flex } from "../../../ui";
import DrivingForcesTraits from "./DrivingForcesTraits";

class DrivingForcesResults extends React.PureComponent {
  static propTypes = {
    data: drivingForcesScore.isRequired,
  };

  render() {
    const { traits } = this.props.data;

    return (
      <Flex m={2} align="flex-start" wrap column>
        <DrivingForcesTraits traits={traits} />
      </Flex>
    );
  }
}

export default DrivingForcesResults;
