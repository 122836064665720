import React from "react";
import { culturePulseScore, trackingDataProp } from "../../../../lib/graphql/propTypes";
import { Flex } from "../../../ui";
import CulturePulseTraits from "./CulturePulseTraits";

class CulturePulseResults extends React.PureComponent {
  static propTypes = {
    data: culturePulseScore.isRequired,
    trackingData: trackingDataProp,
  };

  static defaultProps = {
    trackingData: undefined,
  }

  render() {
    const { traits } = this.props.data;

    return (
      <Flex m={2} align="flex-start" wrap column>
        <CulturePulseTraits traits={traits} trackingData={this.props.trackingData} />
      </Flex>
    );
  }
}

export default CulturePulseResults;
