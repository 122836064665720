import React from "react";
import {
  bool,
  shape,
  string,
  number,
  arrayOf
} from "prop-types";
import {
  Box,
  Flex,
  Text,
  Title
} from "../../../../../ui";
import HorizontalLineChart from "../../../../../ui/HorizontalLineChart";
import { isNullOrUndefined } from "../../../../../utils";
import UserTraitSection from "../../UserTraitSection";
import { useLocale } from "@/lib/hooks";

function UserComparisonThinkingStyleResults({
  hideUserSection = false,
  value,
  content = {},
  range,
  showUserComparisonChart,
  title,
  primaryUser,
  secondaryUser,
}) {
  const { t } = useLocale();

  const renderTitle = () => {
    if (!title) return null;

    return (
      <Flex m={1} mt={2} align="center" column>
        <Title small>{title.name}</Title>
      </Flex>
    );
  };

  const renderUserSection = () => {
    if (!primaryUser || !secondaryUser) {
      return null;
    }

    return (
      <Flex m={1}>
        <UserTraitSection user={primaryUser} />
        <UserTraitSection
          user={secondaryUser}
          secondary
          emptyMessage={t("compare-profile.take-assessment-to-compare")}
        />
      </Flex>
    );
  };

  const { paragraph1, paragraph2 } = content || {};

  return (
    <React.Fragment>
      {paragraph1 && (
        <Box>
          <Text fontSize="16px">{paragraph1}</Text>
        </Box>
      )}
      {!hideUserSection && renderUserSection()}
      {paragraph2 && (
        <Box>
          <Text fontSize="16px">{paragraph2}</Text>
        </Box>
      )}
      {renderTitle()}
      {showUserComparisonChart && (
        <Box mt={2}>
          <HorizontalLineChart
            grayscale={isNullOrUndefined(value)}
            value={value}
            range={range}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

UserComparisonThinkingStyleResults.propTypes = {
  hideUserSection: bool,
  value: number,
  content: shape({
    paragraph1: string,
    paragraph2: string,
  }),
  range: shape({
    min: shape({ value: number.isRequired, label: string.isRequired })
      .isRequired,
    max: shape({ value: number.isRequired, label: string.isRequired })
      .isRequired,
  }),
  title: shape({ name: string }),
  primaryUser: shape({
    id: string.isRequired,
    fullName: string.isRequired,
    traitTips: arrayOf(string).isRequired,
  }),
  secondaryUser: shape({
    id: string.isRequired,
    fullName: string.isRequired,
    traitTips: arrayOf(string).isRequired,
  }),
  showUserComparisonChart: bool.isRequired,
};

export default UserComparisonThinkingStyleResults;
