import React from "react";
import { bool } from "prop-types";
import { trackingDataProp } from "../../../../lib/graphql/propTypes";
import { Flex, Box } from "../../../ui";
import { useContentManager } from "../../ContentManager";
import TitleToggleContainer from "../../TitleToggle";
import { enneagramProp } from "./enneagram.propTypes";
import EnneagramTraits from "./EnneagramTraits";

function EnneagramResults({ data, trackingData, startExpanded = false, smallGraph = false }) {
  const {
    graphic, wing, growth, stress, triad, title,
  } = data;
  const { wrapContent } = useContentManager();

  return (
    <Flex w={1} p={2} align="flex-start" wrap column>
      <Box w={1}>
        <TitleToggleContainer
          title={`${title.name}: ${title.secondaryName}`}
          description={wrapContent(title)}
          startExpanded={startExpanded}
          trackingData={trackingData}
        />
      </Box>
      <EnneagramTraits
        wing={wing}
        growth={growth}
        stress={stress}
        triad={triad}
        trackingData={trackingData}
      />
      <Flex justify="center" w={1} p={1}>
        <Flex w={smallGraph ? 7.5 / 10 : 1} p={1}>
          <img width="100%" height="100%" src={graphic} alt="Enneagram Graph" />
        </Flex>
      </Flex>
    </Flex>
  );
}

EnneagramResults.propTypes = {
  data: enneagramProp.isRequired,
  startExpanded: bool,
  smallGraph: bool,
  trackingData: trackingDataProp,
};

export default EnneagramResults;
